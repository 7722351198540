import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const InstagramSection = () => {
  const data = useStaticQuery(graphql`
    query instagramSectionData {
      allFile(
        filter: {
          relativePath: {
            in: [
              "instagram/1.jpg"
              "instagram/2.jpg"
              "instagram/3.jpg"
              "instagram/4.jpg"
              "instagram/5.jpg"
              "instagram/6.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const instagramImages = data.allFile.edges;

  return (
    <div className="hidden lg:grid grid-cols-6 gap-2">
      {instagramImages.map(({ node: image }) => (
        <div
          key={image.relativePath}
          className="h-48 bg-gray-300 relative w-full"
        >
          <Img
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            className="object-cover w-full h-full"
            fluid={image.childImageSharp.fluid}
          />
        </div>
      ))}
    </div>
  );
};

export default InstagramSection;
