import React from "react";
import useAuth from "../../hooks/useAuth";
import { navigate } from "gatsby";
import { isBrowser } from "../../utils/isBrowser";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { state } = useAuth();
  const isAuthenticated =
    state !== undefined &&
    state.isLoggedIn &&
    state.isActive &&
    !state.isExpired;

  if (isBrowser && !isAuthenticated) {
    navigate("/");
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
