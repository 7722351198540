import React from "react";
import { convertToTwoDecimalCase } from "../../utils/converters";

const OrderGrid = ({ item, remove, edit = true }) => {
  const colorVariant = item.product.variants.find((v) => v.name === "color");
  const sizeVariant = item.product.variants.find((v) => v.name === "size");
  const lurexVariant = item.product.variants.find((v) => v.name === "lurex");

  let size = item.product.properties.find(
    (p) => sizeVariant !== undefined && p.relationKey === sizeVariant._id
  );

  let color = item.product.properties.find(
    (p) =>
      (colorVariant !== undefined && p.relationKey === colorVariant._id) ||
      (lurexVariant !== undefined && p.relationKey === lurexVariant._id)
  );

  return (
    <div className="flex space-x-4 text-gray-700 mb-4">
      {edit && (
        <div className="place-self-center">
          <button
            onClick={() => {
              remove(item._id);
            }}
          >
            x
          </button>
        </div>
      )}
      <div className="w-32 rounded-lg relative">
        {item.product.images && item.product.images.length > 0 && (
          <img
            src={item.product.images[0].secure_url}
            style={{ height: "100%", width: "100%" }}
            className="object-cover rounded-lg"
            alt={item.product.title}
          />
        )}
        <span className="absolute top-0 right-0 bg-fuschia-700 text-white leading-none -mr-2.5 -mt-2 rounded-full w-5 h-5 flex items-center justify-center font-extrabold text-xs">
          {item.quantity}
        </span>
      </div>
      <div className="flex flex-col divide-y divide-gray-300 text-sm flex-grow">
        <div className="py-1">{item.product.title}</div>
        <div>
          <div className="py-1">Size: {size ? size.value : "NA"}</div>
          <div className="py-1 capitalize">
            {color ? `${`Color: ${color.value} - ${color.slug}`}` : "Color: NA"}
          </div>
        </div>
        <div className="py-1">
          {convertToTwoDecimalCase(item.price.unity)} €
        </div>
      </div>
    </div>
  );
};

export default OrderGrid;
