import React from "react";
import { Router } from "@reach/router";

import PrivateRoute from "../components/private-route";
import Main from "./main";
import Bag from "./bag";
import Checkout from "./checkout";
import Account from "./account";

const Platform = () => {
  return (
    <Router basepath="/platform">
      <PrivateRoute path="/bag" component={Bag} />
      <PrivateRoute path="/checkout" component={Checkout} />
      <PrivateRoute path="/account" component={Account} />
      <PrivateRoute default component={Main} />
    </Router>
  );
};

export default Platform;
