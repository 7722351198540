import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { navigate } from "gatsby";
import useAuth from "../../hooks/useAuth";
import ShopLayout from "../../components/new-layout";
import Breadcrumbs from "../../components/breadcrumbs";
import BagGrid from "../../components/bag-grid";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { convertToTwoDecimalCase } from "../../utils/converters";
import { createOrderAsync } from "../../services/checkout";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { GetAuthContextFromLS } from "../../utils/secure-local-storage";

const Checkout = () => {
  const {
    state,
    resetBag,
    getAddressesByTokenAsync,
    validateSession,
  } = useAuth();

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [submitOrder, setSubmitOrder] = useState(false);

  const schema = yup.object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup.string().email("Invalid email"),
    taxIdentifierNumber: yup
      .number()
      .required("Tax identifier number is required"),
    address: yup.string().required("Address is required."),
    addressComp: yup.string(),
    country: yup.string().required("Country is required"),
    region: yup.string().required("Region is required"),
    city: yup.string().required("City is required"),
    zipCode: yup.string().required("Postal or ZipCode is required"),
    phone: yup.string().required("Phone is required"),
    isDefault: yup.bool(),
  });

  const findAttribute = (attributeName) => {
    if (state.attributes === null || state.attributes === undefined) return "";

    let attribute = state.attributes.find((o) => o.name === attributeName);
    if (attribute === null || attribute === undefined) return "";

    return attribute.value;
  };

  let initialValues = {
    firstName: state.name.split(" ")[0],
    lastName: state.name.split(" ")[1],
    email: state.email,
    taxIdentifierNumber: findAttribute("taxIdentifierNumber"),
    address: findAttribute("address"),
    addressComp: findAttribute("companyName"),
    country: findAttribute("country"),
    region: findAttribute("region"),
    city: findAttribute("city"),
    zipCode: findAttribute("zipCode"),
    phone: findAttribute("phone"),
    deliveryDate: null,
    note: null,
    isDefault: false,
  };

  let emptyBag = state.bag.items.length === 0;
  let emptyAddresses = state.addresses.length === 0;

  if (emptyBag) navigate("/platform");

  let links = [
    ["/platform", "Home"],
    ["/platform/bag", "Bag"],
    ["/platform/checkout", "Checkout"],
  ];

  const submitHandler = async (data) => {
    setSubmitOrder(true);

    let result = await createOrderAsync(
      data,
      selectedAddress,
      state.token,
      state.bag
    );

    setSubmitOrder(false);

    if (!result) {
      const data = GetAuthContextFromLS();
      await validateSession(data);
      return;
    }

    await resetBag();
    getAddressesByTokenAsync(state.token);
  };

  const addressChangedHandler = (e, values, setValues) => {
    let addressId = e.target.value;
    let address = state.addresses.find((a) => a._id === addressId);

    values.firstName = address.firstName;
    values.lastName = address.lastName;
    values.address = address.address;
    values.addressComp = address.addressComp;
    values.country = address.country;
    values.region = address.region;
    values.city = address.city;
    values.zipCode = address.zipCode;
    values.phone = address.phone;
    values.isDefault = address.isDefault;
    values.email = address.email;
    values.taxIdentifierNumber = address.taxIdentifierNumber;

    setValues(values);
    setSelectedAddress(e.target.value);
  };

  const DatePickerField = ({ name, value, onChange }) => {
    return (
      <DatePicker
        name="deliveryDate"
        className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
        placeholderText="Delivery Date"
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          onChange(name, val);
        }}
      />
    );
  };

  return (
    <ShopLayout>
      <Breadcrumbs links={links} />
      <div className="border-b-2 border-dashed border-gray-300">
        <div className="container md:pl-12 mx-auto">
          <div
            className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16 lg:gap-y-4 py-10"
            style={{
              gridTemplateRows: "auto 1fr",
            }}
          >
            <div>
              <h3 className="font-display text-blue-900 text-xl lg:text-2xl mb-3 border-gray-300 border-b">
                Order review
              </h3>
              {state.bag.items.map((item) => (
                <BagGrid item={item} key={`${item.id}`} edit={false} />
              ))}
              <div className="text-gray-700 mt-8 right-0">
                <div className="cart-total">
                  <div className="py-4 font-display text-lg text-blue-900 border-t border-gray-300">
                    Shipping costs
                  </div>
                  <div className="py-4 border-t border-gray-300">
                    {convertToTwoDecimalCase(state.bag.shipping)} €
                  </div>
                </div>
              </div>
              <div className="text-gray-700 mb-10 right-0">
                <div className="cart-total">
                  <div className="py-4 font-display text-lg text-blue-900 border-t border-gray-300">
                    Total of your order
                  </div>
                  <div className="py-4 border-t border-gray-300">
                    {convertToTwoDecimalCase(state.bag.total)} €
                  </div>
                </div>
              </div>
            </div>
            <div className="order-2 lg:order-first">
              <Formik
                validationSchema={schema}
                onSubmit={submitHandler}
                initialValues={initialValues}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  setValues,
                  touched,
                  errors,
                  setFieldValue,
                }) => (
                  <form
                    className="bg-pink-500 p-6 text-white rounded-2xl"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <h3 className="font-display text-white text-xl lg:text-2xl mb-3">
                      {emptyAddresses
                        ? "Please create a address"
                        : "Choose or create a new address"}
                    </h3>
                    {!emptyAddresses && (
                      <>
                        <select
                          onChangeCapture={(e) => {
                            addressChangedHandler(e, values, setValues);
                          }}
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          defaultValue="Select one shipping address or create a new one"
                        >
                          <option disabled={true}>
                            Select one shipping address or create a new one
                          </option>
                          {state.addresses.map((address) => {
                            return (
                              <option key={address._id} value={address._id}>
                                {address.firstName} {address.lastName} -{" "}
                                {address.address}
                              </option>
                            );
                          })}
                        </select>
                        <div className="border-b border-white mb-5"></div>
                      </>
                    )}
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                      <div>
                        <input
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          type="text"
                          required
                          name="firstName"
                          placeholder="First Name"
                          value={values.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && touched.firstName && (
                          <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                            {errors.firstName}
                          </p>
                        )}
                      </div>
                      <div>
                        <input
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && touched.lastName && (
                          <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                            {errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <input
                      className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={values.address}
                      onChange={handleChange}
                    />
                    {errors.address && touched.address && (
                      <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                        {errors.address}
                      </p>
                    )}
                    <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                      <div className="col-span-1">
                        <DatePickerField
                          name="deliveryDate"
                          value={values.deliveryDate}
                          onChange={setFieldValue}
                        />
                      </div>
                      <div className="col-span-2">
                        <input
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          type="text"
                          name="addressComp"
                          placeholder="Company Name"
                          value={values.addressComp}
                          onChange={handleChange}
                        />
                        {errors.addressComp && touched.addressComp && (
                          <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                            {errors.addressComp}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                      <div className="col-span-2">
                        <input
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          type="email"
                          name="email"
                          placeholder="Email (optional)"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email && (
                          <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                            {errors.email}
                          </p>
                        )}
                      </div>
                      <div>
                        <input
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          type="text"
                          name="taxIdentifierNumber"
                          placeholder="Tax Identifier Number"
                          value={values.taxIdentifierNumber}
                          onChange={handleChange}
                        />
                        {errors.taxIdentifierNumber &&
                          touched.taxIdentifierNumber && (
                            <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                              {errors.taxIdentifierNumber}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                      <CountryDropdown
                        className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                        defaultOptionLabel="Country"
                        name="country"
                        value={values.country}
                        onChange={(_, e) => handleChange(e)}
                      />
                      <RegionDropdown
                        className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                        blankOptionLabel="Region"
                        disableWhenEmpty={true}
                        name="region"
                        country={values.country}
                        value={values.region}
                        onChange={(_, e) => handleChange(e)}
                      />
                      <input
                        className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                        type="text"
                        name="zipCode"
                        placeholder="ZipCode"
                        value={values.zipCode}
                        onChange={handleChange}
                      />
                      {errors.zipCode && touched.zipCode && (
                        <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                          {errors.zipCode}
                        </p>
                      )}
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <input
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          type="text"
                          name="city"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                        />
                        {errors.city && touched.city && (
                          <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                            {errors.city}
                          </p>
                        )}
                      </div>
                      <div>
                        <input
                          className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        {errors.phone && touched.phone && (
                          <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                            {errors.phone}
                          </p>
                        )}
                      </div>
                    </div>
                    <textarea
                      className="bg-pink-700 border-2 border-pink-700 rounded-lg w-full px-4 py-4 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4"
                      name="note"
                      placeholder="type here notes about your order ..."
                      value={values.note}
                      onChange={handleChange}
                    />
                    <div className="text-right">
                      <button
                        type="submit"
                        className="font-display bg-blue-700 border-2 border-blue-700 rounded-lg px-8 py-3 placeholder-gray-100 focus:outline-none focus:border-pink-900 mt-4 md:mt-0 md:mb-4 resize-none"
                        disabled={submitOrder}
                      >
                        {submitOrder ? "Submitting order" : "Submit order"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </ShopLayout>
  );
};

export default Checkout;
