import React, { useState } from "react";
import { DoubleDownArror, DoubleRightArror } from "../shop-icons";
import OrderGrid from "../../components/order-grid";
import OrderTableRow from "../../components/order-table-row";
import { convertToTwoDecimalCase } from "../../utils/converters";

const Order = ({ order }) => {
  const [open, setOpen] = useState(false);

  const address = order.addresses[0];

  const customerName =
    address && address !== undefined
      ? `${address.firstName} ${address.lastName}`
      : order.customer.name;

  return (
    <>
      <div
        className={`grid grid-cols-2 lg:gap-16 gap-y-4 md:gap-y-4 lg:gap-y-4 pt-4 border-t-2 ${
          open ? "border-b-2" : null
        } border-dashed border-gray-300`}
        style={{
          gridTemplateRows: "auto 1fr",
        }}
      >
        <div>
          <button
            className="mr-2 align-middle"
            onClick={() => setOpen((prevState) => !prevState)}
          >
            {open ? (
              <DoubleDownArror className="w-3 h-3 md:w-6 md:h-6 text-gray-500" />
            ) : (
              <DoubleRightArror className="w-3 h-3 md:w-6 md:h-6 text-gray-500" />
            )}
          </button>
          <h4 className="font-display text-lg text-gray-500 text-left inline-block">
            <span className="hidden md:inline-block">Order number: </span>{" "}
            <span className="text-gray-700">{order.code}</span>
            <span className="hidden md:inline-block ml-5 rounded-full text-white text-sm bg-green-500 px-2 md:px-4 py-1">
              {order.status}
            </span>
          </h4>
        </div>
        <div className="text-right">
          <h4 className="font-display hidden md:inline-block text-lg text-gray-500 mr-5">
            <span className="hidden md:inline-block">Total: </span>{" "}
            <span className="text-gray-700">
              {convertToTwoDecimalCase(order.price.total)} €
            </span>
          </h4>
          <h4 className="font-display hidden lg:inline-block text-lg text-gray-500 mr-5">
            <span className="text-gray-700">{customerName}</span>
          </h4>
          <h4 className="font-display text-lg text-gray-500 inline-block">
            <span className="text-gray-700">
              {new Date(order.createdAt).toLocaleDateString()}
            </span>
          </h4>
        </div>
      </div>
      {open && (
        <>
          <div className="lg:hidden mt-8">
            {order.lines.map((line, i) => (
              <OrderGrid item={line} key={`${line._id}`} edit={false} />
            ))}
          </div>
          <table className="hidden lg:table w-full mt-8 text-lg">
            <thead className="font-display text-left text-blue-900">
              <tr>
                <th className="font-normal py-4 border-b border-gray-300">
                  {""}
                </th>
                <th className="font-normal py-4 border-b border-gray-300">
                  Product
                </th>
                <th className="font-normal text-center py-4 border-b border-gray-300">
                  Size
                </th>
                <th className="font-normal text-center py-4 border-b border-gray-300">
                  Color
                </th>
                <th className="font-normal text-center py-4 border-b border-gray-300">
                  Price
                </th>
                <th className="font-normal text-center py-4 border-b border-gray-300">
                  Quantity
                </th>
                <th className="font-normal text-center py-4 border-b border-gray-300 w-36">
                  Total
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {order.lines.map((line, i) => (
                <OrderTableRow
                  key={`${line._id}`}
                  item={line}
                  edit={false}
                  showBorder={!(i + 1 === order.lines.length)}
                />
              ))}
            </tbody>
          </table>
          {((order.note && order.note !== undefined) ||
            (order.delivery !== undefined && order.delivery.expectedAt)) && (
            <div className="text-gray-700 mt-8 mb-10">
              <div className="cart-total">
                {order.delivery !== undefined && order.delivery.expectedAt && (
                  <>
                    <div className="py-4 font-display text-lg text-blue-900 border-b border-t border-gray-300">
                      Delivery Date
                    </div>
                    <div className="py-4 border-b border-t border-gray-300">
                      {new Date(order.delivery.expectedAt).toLocaleDateString()}
                    </div>
                  </>
                )}
                {order.note !== undefined && order.note && (
                  <>
                    <div className="py-4 font-display text-lg text-blue-900 border-gray-300">
                      Notes
                    </div>
                    <div className="py-4 border-gray-300">{order.note}</div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Order;
