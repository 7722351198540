import React from "react";
import { Link } from "gatsby";
import { convertToTwoDecimalCase } from "../../utils/converters";

const OrderTableRow = ({ item, remove, edit = true, showBorder = true }) => {
  const colorVariant = item.product.variants.find((v) => v.name === "color");
  const sizeVariant = item.product.variants.find((v) => v.name === "size");
  const lurexVariant = item.product.variants.find((v) => v.name === "lurex");

  let size = item.product.properties.find(
    (p) => sizeVariant !== undefined && p.relationKey === sizeVariant._id
  );
  let color = item.product.properties.find(
    (p) =>
      (colorVariant !== undefined && p.relationKey === colorVariant._id) ||
      (lurexVariant !== undefined && p.relationKey === lurexVariant._id)
  );

  let className = showBorder ? "border-b border-gray-300" : null;

  return (
    <tr>
      <td className={`py-4 px-2 ${className}`}>
        {edit && (
          <button
            onClick={() => {
              remove(item._id);
            }}
          >
            x
          </button>
        )}
      </td>
      <td className={`py-8 flex items-center ${className}`}>
        <div className="bg-gray-300 w-24 h-24 rounded-lg mr-4 relative shadow">
          {item.product.images && item.product.images.length > 0 && (
            <img
              src={item.product.images[0].secure_url}
              style={{ height: "100%", width: "100%" }}
              className="object-cover rounded-lg"
              alt={item.product.title}
            />
          )}
        </div>
        <Link to={`/product/${item.product._id}`}>{item.product.title}</Link>
      </td>
      <td className={`py-4 text-center ${className}`}>
        {size ? size.value : "NA"}
      </td>
      <td className={`text-center ${className}`}>
        {color ? (
          <div className="w-12 h-12 mx-auto">
            <img
              src={color.image.secure_url}
              width={100}
              height={100}
              loading="lazy"
              className="h-full w-full object-cover rounded-full shadow"
              alt={color.slug}
            />
          </div>
        ) : (
          "NA"
        )}
      </td>
      <td className={`text-center ${className}`}>
        {convertToTwoDecimalCase(item.price.unity)} €
      </td>
      <td className={`text-center ${className}`}>
        <span className="text-lg w-6 text-center py-2">{item.quantity}</span>
      </td>
      <td className={`text-center ${className}`}>
        {convertToTwoDecimalCase(item.price.total)} €
      </td>
    </tr>
  );
};

export default OrderTableRow;
