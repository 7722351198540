import React from "react";
import Product from "../product";

const ProductList = ({ title, description, products }) => {
  return (
    <div className="container lg:max-w-screen-lg pb-24 relative">
      <div className="text-center mb-32">
        <h1 className="text-4xl font-display text-blue-900 mb-4">{title}</h1>
        <p className="mx-auto md:w-6/12 text-blue-700 text-lg">{description}</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-10">
        {products.map(({ node: product }) => (
          <Product product={product} key={product._id} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
