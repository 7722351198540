import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ShopLayout from "../../components/new-layout";
import Collection from "../../components/collection";
import Paws from "../../components/paws";
import ProductList from "../../components/product-list";
import Newsletter from "../../components/shop-newsletter";
import InstagramSection from "../../components/instagram-section";

const Main = () => {
  const data = useStaticQuery(graphql`
    query mainData {
      allProduct(limit: 12, filter: { season: { eq: "Home" } }) {
        edges {
          node {
            _id
            title
            remoteImage {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            price {
              basePrice
              maxPrice
            }
          }
        }
      }
      banner: file(relativePath: { eq: "banner.svg" }) {
        publicURL
      }
      bannerText: file(relativePath: { eq: "banner-text.svg" }) {
        publicURL
      }
    }
  `);

  const productNodes = data.allProduct.edges;
  const banner = data.banner.publicURL;
  const bannerText = data.bannerText.publicURL;

  return (
    <ShopLayout>
      <section className="relative w-full h-36 md:h-56 lg:h-72 bg-pink-700 flex items-center">
        <div className="flex-grow w-full h-full overflow-x-hidden relative">
          <img
            src={banner}
            className="absolute inset-0 my-auto w-full lg:h-72 object-cover object-right"
            alt="meia pata banner"
          />
        </div>
        <img
          src={bannerText}
          className="flex-shrink-0 ml-auto mr-8 w-5/12 max-w-md"
          alt="meia pata text"
        />
      </section>
      <div className="container grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 xl:gap-10 my-8 md:my-16 mb-12 text-white lg:max-w-screen-lg">
        <Collection
          title="Girl"
          href="/store/category/female"
          className="bg-pink-300"
        />
        <Collection
          title="Boy"
          href="/store/category/male"
          className="bg-blue-300"
        />
        <Collection
          title="Accessories"
          href="/store/category/accessories"
          className="bg-yellow-500"
        />
      </div>
      <div className="relative">
        <div className="absolute top-0 bottom-0 right-0 left-0 overflow-hidden z-0">
          <Paws
            className="fill-current text-yellow-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "19deg",
              right: "10%",
              top: 10,
            }}
          />
          <Paws
            className="fill-current text-pink-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "10deg",
              right: "40%",
              top: 115,
            }}
          />
          <Paws
            className="fill-current text-blue-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "1%",
              top: 200,
            }}
          />
          <Paws
            className="fill-current text-blue-100 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "-40deg",
              left: "44%",
              top: 700,
            }}
          />
          <Paws
            className="fill-current text-pink-300 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              right: "1%",
              top: 800,
            }}
          />
          <Paws
            className="fill-current text-yellow-500 transform -rotate-45 absolute"
            style={{
              "--tw-rotate": "27deg",
              left: "30%",
              top: 1350,
            }}
          />
        </div>
        <ProductList
          title="New Arrivals"
          description="100% made in Portugal, with quality of excellence."
          products={productNodes}
        />
      </div>
      <Newsletter />
      <InstagramSection />
    </ShopLayout>
  );
};

export default Main;
