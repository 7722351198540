import React, { useState, useLayoutEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import useAuth from "../../hooks/useAuth";
import ShopLayout from "../../components/new-layout";
import Breadcrumbs from "../../components/breadcrumbs";
import Order from "../../components/order";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Logout } from "../../components/shop-icons";
import { getOrdersAsync } from "../../services/checkout";
import { updateUserByIdAsync } from "../../services/auth";

const Account = () => {
  const { state, logout, getUserInformationByIdAsync } = useAuth();
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [orders, setOrders] = useState([]);
  const [editing, setEditing] = useState(false);

  useLayoutEffect(() => {
    handleGetOrdersAsync();
  }, []);

  const handleGetOrdersAsync = async () => {
    setIsLoadingOrders(true);
    let orders = await getOrdersAsync(state.token);

    if (!orders) logout();

    setOrders(orders);
    setIsLoadingOrders(false);
  };

  const schema = yup.object({
    fullName: yup.string().required("Full name is required."),
    taxIdentifierNumber: yup
      .string()
      .required("Tax identifier number is required"),
    address: yup.string().required("Address is required."),
    addressComp: yup.string().required("Company name is required"),
    country: yup.string().required("Country is required"),
    region: yup.string().required("Region is required"),
    city: yup.string().required("City is required"),
    zipCode: yup.string().required("Postal or ZipCode is required"),
    phone: yup.string().required("Phone is required"),
    isDefault: yup.bool(),
  });

  const findAttribute = (attributeName) => {
    if (state.attributes === null || state.attributes === undefined) return "";

    let attribute = state.attributes.find((o) => o.name === attributeName);
    if (attribute === null || attribute === undefined) return "";

    return attribute.value;
  };

  let initialValues = {
    fullName: state.name,
    taxIdentifierNumber: findAttribute("taxIdentifierNumber"),
    address: findAttribute("address"),
    addressComp: findAttribute("companyName"),
    country: findAttribute("country"),
    region: findAttribute("region"),
    city: findAttribute("city"),
    zipCode: findAttribute("zipCode"),
    phone: findAttribute("phone"),
    isDefault: true,
  };

  let links = [
    ["/platform", "Home"],
    ["/platform/account", "Main Account"],
  ];

  const handleChanges = async (data) => {
    let optionFields = [...state.attributes];

    if (findAttribute("address") === "")
      optionFields.push({ name: "address", value: data.address });

    if (findAttribute("companyName") === "")
      optionFields.push({ name: "companyName", value: data.addressComp });

    if (findAttribute("country") === "")
      optionFields.push({ name: "country", value: data.country });

    if (findAttribute("region") === "")
      optionFields.push({ name: "region", value: data.region });

    if (findAttribute("city") === "")
      optionFields.push({ name: "city", value: data.city });

    if (findAttribute("zipCode") === "")
      optionFields.push({ name: "zipCode", value: data.zipCode });

    if (findAttribute("phone") === "")
      optionFields.push({ name: "phone", value: data.phone });

    await updateUserByIdAsync(state.uuid, optionFields, state.token);
    await getUserInformationByIdAsync(state.uuid, state.token);
  };

  return (
    <ShopLayout>
      <Breadcrumbs links={links} />
      <div className="border-b-2 border-dashed border-gray-300">
        <div className="container md:pl-12 mx-auto">
          <div
            className="grid grid-cols-2 lg:gap-16 lg:gap-y-4"
            style={{
              gridTemplateRows: "auto 1fr",
            }}
          >
            <h3 className="font-display text-blue-900 text-xl lg:text-2xl mt-20">
              Account details:
            </h3>
            <div className="text-right mt-5">
              <button
                className="font-display text-blue-900 text-base"
                onClick={logout}
              >
                Logout <Logout className="w-3 h-3 md:w-6 md:h-6 inline-block" />
              </button>
            </div>
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={handleChanges}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              setValues,
              touched,
              errors,
            }) => (
              <form
                className="bg-white text-blue-900"
                noValidate
                onSubmit={handleSubmit}
              >
                <div
                  className="grid grid-cols-1 lg:grid-cols-2 lg:gap-16 lg:gap-y-4 pt-2"
                  style={{
                    gridTemplateRows: "auto 1fr",
                  }}
                >
                  <div>
                    <input
                      className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                      type="text"
                      required
                      disabled={true}
                      readOnly={true}
                      name="fullName"
                      placeholder="Full Name"
                      value={values.fullName}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <input
                        className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                        type="email"
                        name="email"
                        placeholder="Email"
                        readOnly={true}
                        disabled={true}
                        value={state.email}
                      />
                      <input
                        className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                        type="text"
                        required
                        disabled={true}
                        name="taxIdentifierNumber"
                        placeholder="Tax Identifier Number"
                        value={values.taxIdentifierNumber}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                      <CountryDropdown
                        className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                        name="country"
                        value={values.country}
                        disabled={findAttribute("country") !== ""}
                        onChange={(_, e) => {
                          handleChange(e);
                        }}
                      />
                      <RegionDropdown
                        className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                        blankOptionLabel="Region"
                        disableWhenEmpty={true}
                        name="region"
                        country={values.country}
                        disabled={findAttribute("region") !== ""}
                        value={values.region}
                        onChange={(_, e) => {
                          handleChange(e);
                        }}
                      />
                      <input
                        className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                        type="text"
                        name="zipCode"
                        placeholder="ZipCode"
                        disabled={findAttribute("zipCode") !== ""}
                        value={values.zipCode}
                        onChange={(e) => {
                          setEditing(true);
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <input
                      className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                      type="text"
                      name="address"
                      placeholder="Address"
                      disabled={findAttribute("address") !== ""}
                      value={values.address}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <input
                      className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                      type="text"
                      name="addressComp"
                      placeholder="Company name"
                      value={values.addressComp}
                      disabled={findAttribute("companyName") !== ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <input
                          className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                          type="text"
                          name="city"
                          placeholder="City"
                          value={values.city}
                          disabled={findAttribute("city") !== ""}
                          onChange={(e) => {
                            setEditing(true);
                            handleChange(e);
                          }}
                        />
                      </div>
                      <div>
                        <input
                          className="bg-gray-100 border-2 border-gray-100 rounded-lg w-full px-4 py-4 placeholder-blue-700 focus:outline-none focus:border-blue-900 mb-4"
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          value={values.phone}
                          disabled={findAttribute("phone") !== ""}
                          onChange={(e) => {
                            setEditing(true);
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {(errors.city ||
                    errors.zipCode ||
                    errors.phone ||
                    errors.country ||
                    errors.region ||
                    errors.address ||
                    errors.addressComp) &&
                    touched.country &&
                    touched.address &&
                    touched.region &&
                    touched.city &&
                    touched.zipCode &&
                    touched.phone &&
                    touched.addressComp && (
                      <p className="text-pink-900 font-bold text-xs -mt-2 mb-3 ml-1">
                        * Form unfinished, please complete all the fields.
                      </p>
                    )}
                </div>
                <div className="text-right">
                  <button
                    type="submit"
                    className={`${
                      editing ? null : "hidden"
                    } font-display bg-gray-100 border-2 border-blue-700 rounded-lg px-8 placeholder-gray-100 focus:outline-none focus:border-pink-900 mb-4 py-3 resize-none`}
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </Formik>
          {orders && orders.length > 0 && !isLoadingOrders ? (
            <div className="mb-10">
              <h3 className="font-display text-blue-900 text-xl lg:text-2xl mb-5 mt-10">
                Order history:
              </h3>
              {orders.map((o) => (
                <Order key={o._id} order={o} />
              ))}
            </div>
          ) : isLoadingOrders ? (
            <div className="text-center my-20">
              <p className="font-display text-lg text-gray-500 ">
                Loading previous orders ...
              </p>
            </div>
          ) : (
            <div className="mb-10">
              <h3 className="font-display text-blue-900 text-xl lg:text-2xl mb-3 mt-10">
                You order history is empty.
              </h3>
            </div>
          )}
        </div>
      </div>
    </ShopLayout>
  );
};

export default Account;
