import React from "react";
import { Link } from "gatsby";

const Collection = ({ title, href, className }) => {
  return (
    <div className={`rounded-lg relative p-2 ${className}`}>
      <div className="flex items-center justify-center border-2 border-dashed border-white rounded-lg h-40">
        <span className="text-3xl font-display">{title}</span>
      </div>
    </div>
  );
};

export default Collection;
