import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import {
  convertToTwoDecimalCase,
  convertProductPriceToUserPrice,
} from "../../utils/converters";

const Product = ({ product, userGroups }) => {
  let productPrice = convertProductPriceToUserPrice(userGroups, product);

  let labelPrice =
    productPrice.maxPrice === productPrice.basePrice
      ? convertToTwoDecimalCase(productPrice.basePrice)
      : `${convertToTwoDecimalCase(
          productPrice.basePrice
        )} - ${convertToTwoDecimalCase(productPrice.maxPrice)}`;

  let haveImage =
    product.remoteImage !== undefined && product.remoteImage !== null;

  return (
    <Link to={`/product/${product._id}`}>
      <div className="text-center cursor-pointer">
        <div className="bg-gray-300 h-64 mb-4 rounded-lg w-full relative shadow-sm">
          {haveImage && (
            <Img
              style={{ height: "100%", width: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              className="object-cover rounded-lg"
              fluid={product.remoteImage.childImageSharp.fluid}
            />
          )}
        </div>
        <div>
          <span className="text-blue-900 text-xl font-display">
            {product.title}
          </span>
          <div className="text-fuschia-700 font-extrabold text">
            {labelPrice} €
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Product;
