import React from "react";
import Img from "gatsby-image";
import { convertToTwoDecimalCase } from "../../utils/converters";

const BagGrid = ({ item, remove, edit = true }) => {
  let size = item.product.properties.find(
    (p) => p.relationKey === item.product.sizeVariant
  );
  let color = item.product.properties.find(
    (p) =>
      p.relationKey === item.product.colorVariant ||
      p.relationKey === item.product.lurexVariant
  );

  return (
    <div className="flex space-x-4 text-gray-700 mb-4">
      {edit && (
        <div className="place-self-center">
          <button
            onClick={() => {
              remove(item.id);
            }}
          >
            x
          </button>
        </div>
      )}
      <div className="w-32 rounded-lg relative">
        {item.product.image && (
          <Img
            fluid={item.product.image.childImageSharp.fluid}
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            className="object-cover rounded-lg"
          />
        )}
        <span className="absolute top-0 right-0 bg-fuschia-700 text-white leading-none -mr-2.5 -mt-2 rounded-full w-5 h-5 flex items-center justify-center font-extrabold text-xs">
          {item.quantity}
        </span>
      </div>
      <div className="flex flex-col divide-y divide-gray-300 text-sm flex-grow">
        <div className="py-1">{item.product.title}</div>
        <div>
          <div className="py-1">Size: {size ? size.value : "NA"}</div>
          <div className="py-1 capitalize">
            {color ? `${`Color: ${color.value} - ${color.slug}`}` : "Color: NA"}
          </div>
        </div>
        <div className="py-1">{convertToTwoDecimalCase(item.price)} €</div>
      </div>
    </div>
  );
};

export default BagGrid;
