import React from "react";
import { Link, navigate } from "gatsby";
import Swal from "sweetalert2";

const Newsletter = () => {
  return (
    <div className="bg-blue-900 text-white pt-12 pb-6">
      <div className="container">
        <div className="newsletter gap-x-6">
          <div className="text-xl md:text-2xl font-display leading-5 flex-shrink-0 py-2">
            Subscribe to our Newsletter
          </div>
          <div className="flex-grow flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 lg:items-start">
            <div className="flex flex-col flex-grow">
              <input
                required
                name="email"
                type="email"
                placeholder="Your email ..."
                className="bg-gray-100 text-blue-900 placeholder-blue-900 leading-7 py-3 px-6 rounded-lg 
                flex-grow disabled:bg-gray-100 focus:outline-none focus:ring ring-blue-700"
              />
            </div>
            <button
              onClick={() => {
                Swal.fire(
                  "Thank you!",
                  "Newsletter subscribed with success, do some shopping now!",
                  "success"
                );
                navigate("/store");
              }}
              type="submit"
              className="flex text-lg font-display bg-fuschia-700 rounded-lg"
            >
              <span className="px-6 py-3 flex-grow text-left">Subscribe</span>
              <span className="pr-6 py-3">{">"}</span>
            </button>
          </div>
          <label className="lg:col-start-2 mt-2 flex items-center">
            <input
              required
              type="checkbox"
              className="form-tick flex-shrink-0 appearance-none h-5 w-5 bg-white border border-gray-300 rounded-md 
              checked:bg-fuschia-700 checked:border-transparent focus:outline-none cursor-pointer"
            />
            <span className="ml-2 text-sm">
              Read and accept the{" "}
              <Link to="/privacy" className="font-bold">
                Privacy terms
              </Link>{" "}
              of Meia Pata.
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
