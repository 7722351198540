import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { convertToTwoDecimalCase } from "../../utils/converters";

const BagTableRow = ({ item, remove, edit = true }) => {
  let size = item.product.properties.find(
    (p) => p.relationKey === item.product.sizeVariant
  );
  let color = item.product.properties.find(
    (p) =>
      p.relationKey === item.product.colorVariant ||
      p.relationKey === item.product.lurexVariant
  );

  return (
    <tr>
      <td className="py-4 px-2 border-b border-gray-300">
        {edit && (
          <button
            onClick={() => {
              remove(item.id);
            }}
          >
            x
          </button>
        )}
      </td>
      <td className="py-8 flex items-center border-b border-gray-300">
        <div className="bg-gray-300 w-24 h-24 rounded-lg mr-4 relative shadow">
          {item.product.image && (
            <Img
              fluid={item.product.image.childImageSharp.fluid}
              style={{ height: "100%", width: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              className="object-cover rounded-lg"
              alt={item.product.title}
            />
          )}
        </div>
        <Link to={`/product/${item.product._id}`}>{item.product.title}</Link>
      </td>
      <td className="py-4 text-center border-b border-gray-300">
        {size ? size.value : "NA"}
      </td>
      <td className="border-b border-gray-300 text-center">
        {color ? (
          <div className="w-12 h-12 mx-auto">
            <img
              src={color.image.secure_url}
              width={100}
              height={100}
              loading="lazy"
              className="h-full w-full object-cover rounded-full shadow"
              alt={color.slug}
            />
          </div>
        ) : (
          "NA"
        )}
      </td>
      <td className="text-center border-b border-gray-300">
        {convertToTwoDecimalCase(item.product.price)} €
      </td>
      <td className="text-center border-b border-gray-300">
        <span className="text-lg w-6 text-center py-2">{item.quantity}</span>
      </td>
      <td className="text-center border-b border-gray-300">
        {convertToTwoDecimalCase(item.price)} €
      </td>
    </tr>
  );
};

export default BagTableRow;
