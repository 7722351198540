import React from "react";
import { Link } from "gatsby";
import useAuth from "../../hooks/useAuth";
import ShopLayout from "../../components/new-layout";
import Breadcrumbs from "../../components/breadcrumbs";
import BagGrid from "../../components/bag-grid";
import BagTableRow from "../../components/bag-table-row";
import { convertToTwoDecimalCase } from "../../utils/converters";

const Bag = () => {
  const { state, removeItemFromBag } = useAuth();

  let emptyBag = state.bag.items.length === 0;

  const handleRemoveItem = (itemId) => {
    removeItemFromBag(itemId);
  };

  const shippingCosts = JSON.parse(process.env.GATSBY_SHIPPING_COSTS);

  let links = [
    ["/platform", "Home"],
    ["/platform/bag", "Bag"],
  ];

  return (
    <ShopLayout>
      <Breadcrumbs links={links} />
      <div className="border-b-2 border-dashed border-gray-300">
        <div className="container md:pl-12">
          {emptyBag ? (
            <div className="flex flex-col items-center justify-center py-20 leading-none">
              <div className="font-display text-blue-900 text-2xl mb-4">
                Your bag is empty.
              </div>
              <Link
                className="shadow bg-fuschia-700 border border-fuschia-700 py-4 px-10 rounded-md text-white font-display"
                to="/store"
              >
                Back to store.
              </Link>
            </div>
          ) : (
            <>
              <div className="lg:hidden mt-8">
                {state.bag.items.map((item) => (
                  <BagGrid
                    item={item}
                    remove={handleRemoveItem}
                    key={`${item.id}`}
                  />
                ))}
              </div>
              <table className="hidden lg:table w-full mt-8 text-lg">
                <thead className="font-display text-left text-blue-900">
                  <tr>
                    <th className="font-normal py-4 border-b border-gray-300">
                      {""}
                    </th>
                    <th className="font-normal py-4 border-b border-gray-300">
                      Product
                    </th>
                    <th className="font-normal text-center py-4 border-b border-gray-300">
                      Size
                    </th>
                    <th className="font-normal text-center py-4 border-b border-gray-300">
                      Color
                    </th>
                    <th className="font-normal text-center py-4 border-b border-gray-300">
                      Price
                    </th>
                    <th className="font-normal text-center py-4 border-b border-gray-300">
                      Quantity
                    </th>
                    <th className="font-normal text-center py-4 border-b border-gray-300 w-36">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {state.bag.items.map((item) => (
                    <BagTableRow
                      key={`${item.id}`}
                      item={item}
                      remove={handleRemoveItem}
                    />
                  ))}
                </tbody>
              </table>
              <div className="text-gray-700 mt-8 mb-10">
                <div className="py-4 font-display text-xl text-blue-900">
                  Total of your order
                </div>
                <div className="cart-total">
                  <div className="py-4 font-display text-lg text-blue-900 border-b border-gray-300">
                    Subtotal
                  </div>
                  <div className="py-4 border-b border-gray-300">
                    {convertToTwoDecimalCase(state.bag.subTotal)} €
                  </div>
                  <div className="py-4 font-display text-lg text-blue-900 md:border-b border-gray-300 col-span-2 md:col-span-1">
                    Shipping costs
                  </div>
                  <div className="py-4 border-b border-gray-300 flex items-start col-span-2 md:col-span-1">
                    {convertToTwoDecimalCase(state.bag.shipping)} € {convertToTwoDecimalCase(state.bag.shipping) > 0 ? `- For orders under ${shippingCosts.minOrderValue} €` : '' } 
                  </div>
                  <div className="py-4 font-display text-lg text-blue-900 border-b border-gray-300">
                    Total
                  </div>
                  <div className="py-4 border-b border-gray-300">
                    {convertToTwoDecimalCase(state.bag.total)} €
                  </div>
                </div>
                <Link
                  className="my-6 shadow inline-block bg-fuschia-700 border border-fuschia-700 py-3 px-10 rounded-md text-white font-display"
                  to="/platform/checkout"
                >
                  Checkout
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </ShopLayout>
  );
};

export default Bag;
